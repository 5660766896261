@font-face {
  font-family: "Brandon Grotesque";
  src: url("./fonts/BrandonGrotesque-Regular.woff2") format("woff2"),
    url("./fonts/BrandonGrotesque-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("./fonts/BrandonGrotesque-Bold.woff2") format("woff2"),
    url("./fonts/BrandonGrotesque-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  font-family: -apple-system, Helvetica Neue, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Brandon Grotesque", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
  transition: color 0.3s ease-in-out;
}

p + p {
  margin-top: 1em;
}

/* Container */

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto;
}

@media (min-width: 520px) {
  .container {
    padding: 0 2rem;
  }
}

/* Utils */

.hide-mobile {
  display: none;
}

@media (min-width: 520px) {
  .hide-mobile {
    display: flex;
  }
}

/* Home Page - Header */

.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(57, 63, 72, 0.2);
  transition: box-shadow 0.3s ease-out;
  z-index: 20;
}

.header-active .header {
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__logo svg {
  height: 3rem;
  width: auto;
  margin: 0;
}

.header__nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Brandon Grotesque", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

.header__nav a {
  text-decoration: none;
  color: #000828;
}

.header__nav a:hover {
  color: rgba(0, 8, 40, 0.8);
}

.header__nav a + a {
  margin-left: 2rem;
}

@media (min-width: 520px) {
  .header {
    height: 5rem;
  }

  .header__logo svg {
    height: 4rem;
  }
}

/* Home Page - Main / Common */

.main {
  margin-top: 4rem;
}

.section {
  width: 100%;
  padding: 3rem 0;
}

@media (min-width: 520px) {
  .main {
    margin-top: 5rem;
  }

  .section {
    padding: 4rem 0;
  }
}

/* Home Page - Hero */

.hero {
  margin-bottom: 1rem;
}

.hero__title {
  max-width: 90%;
  margin-bottom: 1rem;
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 1.2;
}

.hero__title span {
  display: inline-block;
}

.hero__text {
  width: 90%;
  max-width: 50rem;
  font-size: 1.125rem;
  color: #505050;
}

@media (min-width: 520px) {
  .hero__title {
    font-size: 3.5rem;
  }

  .hero__text {
    font-size: 1.375rem;
  }
}

/* Home Page - News */

.news {
  background-color: #000828;
  color: #fff;
}

.news a {
  color: #fff;
  text-decoration: none;
}

.news__title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.news__item {
  width: 100%;
}

.news__item__inner {
  margin: 20px 0;
}

.news__item__title {
  font-size: 1.25rem;
}

.news__item__image {
  background-size: cover;
  background-position: center;
  height: 150px;
  width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 520px) {
  .news__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .news__item {
    width: 50%;
    margin-bottom: 2rem;
  }

  .news__item__inner {
    margin: 0 20px;
  }

  .news__item__inner:nth-of-type(1),
  .news__item__inner:nth-of-type(3) {
    margin-left: 0;
  }

  .news__item__inner:nth-of-type(2),
  .news__item__inner:nth-of-type(4) {
    margin-right: 0;
  }
}

@media (min-width: 1067px) {
  .news__row {
    flex-wrap: nowrap;
  }

  .news__item {
    width: 25%;
  }
}

/* Home Page - Fields of Service */

.fields-of-service {
  background-color: #000828;
  color: #fff;
}

.fields-of-service__title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.fields-of-service__title::after {
  content: '';
  display: block;
  margin: 1rem 0 3rem;
  width: 3rem;
  height: 3px;
  background-color: currentColor;
  opacity: .7;
}

@media (min-width: 520px) {
  .fields-of-service__title::after {
    margin-bottom: 4rem;
  }
}

.fields-of-service__row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.fields-of-service__item {
  flex-grow: 1;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border-radius: 0.5rem;
  color: #000828;
  background-color: #fff;
}

.fields-of-service__item__title {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.fields-of-service__item__text {
  color: #505050;
}

@media (min-width: 768px) {
  .fields-of-service__row {
    flex-direction: row;
    flex-basis: auto;
  }

  .fields-of-service__item {
    width: calc(50% - 1.5rem);
  }

  .fields-of-service__item:nth-child(1) {
    width: 100%;
  }

  .fields-of-service__item:nth-child(2) {
    margin-right: 1.5rem;
  }

  .fields-of-service__item__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 980px) {
  .fields-of-service__row {
    flex-wrap: nowrap;
  }

  .fields-of-service__item:nth-child(1),
  .fields-of-service__item {
    width: calc(33% - 3rem);
  }

  .fields-of-service__item:nth-child(2) {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
  }
}

/* Home Page - Contact */

.contact {
  background-color: #e6e6e6;
}

.contact__container {
  max-width: 40rem;
  text-align: center;
}

.contact__title {
  font-size: 2.5rem;
  margin-bottom: 1rem
}

.contact__subtitle {
  margin-bottom: 2rem;
  color: #505050;
}

.contact__subtitle a {
  color: #000828;
}

.contact-form {
  max-width: 30rem;
  margin: 0 auto;
}

.form__title {
  line-height: 1;
  margin-bottom: 1rem;
  text-align: center;
}

.form__input,
.form__submit {
  width: 100%;
  margin: 0 0 0.5rem;
  border-radius: 0.5rem;
  font-family: "Brandon Grotesque", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.form__input {
  padding: 0.5rem;
  outline: none;
  border: 1px solid #e6e6e6;
  transition: border 0.3s ease-in-out;
}

.form__input:focus {
  border: 1px solid #000828;
  transition: border 0.3s ease-in-out;
}

.form__input.input--error {
  border-color: #fd4847;
}

.form textarea {
  resize: none;
}

.form__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 1rem;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-weight: bold;
  background-color: #000828;
  border: 1px solid #000828;
  outline: none;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;
}

.form__submit:hover {
  cursor: pointer;
  color: #fff;
  background-color: #030f41;
  border: 1px solid #030f41;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;
}

.form__submit::before {
  display: none;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.75rem 0 0 -0.75rem;
  border: 0.15rem solid #e6e6e6; /* Light grey */
  border-top: 0.15rem solid #000828; /* Blue */
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spin 2s linear infinite;
}

.form.success .form__message {
  color: #000828;
}

.form.error .form__message {
  color: #fd4847;
}

.form.loading .form__submit {
  color: transparent;
  pointer-events: none;
}

.form.loading .form__submit::before {
  display: block;
}

@media (min-width: 520px) {
  .form__input,
  .form__submit {
    margin: 0 0 1rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Home Page - Footer */

.footer {
  width: 100%;
  background-color: #000828;
  color: #fff;
}

.footer__container {
  display: flex;
  flex-direction: column;
}

.footer__left,
.footer__right {
  width: 100%;
  text-align: center;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.footer__logo {
  display: inline-block;
}

.footer__logo svg {
  height: 4rem;
  width: auto;
  margin: 0 0 2rem;
}

.footer__logo path,
.footer__logo g {
  fill: #fff;
}

.footer__left {
  font-size: 0.875rem;
}

.footer__left a {
  font-weight: bold;
  text-decoration: underline;
}

.footer__address {
  max-width: 30rem;
  margin: 0 auto 2rem;
  font-style: normal;
}

.footer__nav {
  width: 100%;
  margin: 1rem 0 2rem;
  font-family: "Brandon Grotesque", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

.footer__nav__link + .footer__nav__link {
  margin-left: 2rem;
}

.social-link__icon {
  height: 1.125rem;
  width: 1.125rem;
}

.social-link + .social-link {
  margin-left: 1rem;
}

.social-link__icon svg * {
  transition: fill 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .footer__container {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__left,
  .footer__right {
    width: 40%;
  }

  .footer__left {
    text-align: left;
  }

  .footer__right {
    text-align: right;
  }
}
